<template>
  <div>
    <VhallDialog
      title="分享"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      customClass="share-dialog"
      width="570px"
    >
      <div class="content">
        <div v-if="isBilingual" style="margin: 0px 0 20px 0px; width: 100%">
          <vh-radio-group v-model="languageType" size="small" text-color="#fff" fill="#FB3A32">
            <vh-radio-button :label="1">中文</vh-radio-button>
            <vh-radio-button :label="2">英文</vh-radio-button>
          </vh-radio-group>
        </div>

        <div class="share-div">
          <ul class="icons">
            <li>
              <i @click="toShare('wechat')"></i>
              <p>{{ languageType === 2 ? 'wechat' : '微信' }}</p>
            </li>
            <li>
              <i @click="toShare('qq')"></i>
              <p>QQ</p>
            </li>
            <li>
              <i @click="toShare('sina')"></i>
              <p>{{ languageType === 2 ? 'weibo' : '微博' }}</p>
            </li>
          </ul>
          <div class="inputCode">
            <vh-input :value="currentUrl" id="linkBox">
              <vh-button slot="append" @click="doCopy">复制</vh-button>
            </vh-input>
          </div>
        </div>
        <div class="code-div">
          <div class="code-img">
            <img :src="env.staticLinkVo.aliQr + currentEncodeUrl" alt="二维码加载失败" />
            <br />
          </div>
          <p class="img-code">手机扫码观看</p>
        </div>
      </div>
    </VhallDialog>
    <VhallDialog
      title="分享"
      :visible.sync="wxDialogVisible"
      :close-on-click-modal="false"
      width="320px"
    >
      <div class="wximg-box">
        <img :src="`//aliqr.e.vhall.com/qr.png?t=${currentEncodeUrl}`" alt="" />
      </div>
      <p class="wximg-intro">
        打开微信，点击底部的“发现”，使用 “扫一扫” 即可将网页分享到我的朋友圈。
      </p>
    </VhallDialog>
  </div>
</template>

<script>
  import Env from '@/api/env';
  export default {
    name: 'share',
    created() {
      console.log('this.shareVo:', this.shareVo);
    },
    props: {
      url: {
        type: String,
        required: false
      },
      shareVo: {
        type: Object,
        required: false
      },
      // 是否双语专题
      isBilingual: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      currentUrl() {
        if (this.isBilingual) {
          return `${this.shareVo.url || this.url}&languageType=${this.languageType}`;
        }
        return this.shareVo.url || this.url;
      },
      // 对 currentUrl 做转码处理
      currentEncodeUrl() {
        return encodeURIComponent(this.currentUrl);
      }
    },
    data() {
      return {
        env: Env,
        dialogVisible: false,
        wxDialogVisible: false,
        languageType: 1 // 双语双流专题生效：1 中文，2：英文, 默认为 1
      };
    },
    methods: {
      toShare(type) {
        let url = '';
        if (type === 'wechat') {
          this.wxDialogVisible = true;
        } else if (type === 'sina') {
          url = `//service.weibo.com/share/share.php?url=${this.currentEncodeUrl}`;
          // url = `http://service.weibo.com/share/share.php?url=${this.shareVo.url || this.url}`
          window.open(url, '_blank');
        } else if (type === 'qq') {
          url = `//connect.qq.com/widget/shareqq/index.html?url=${this.currentEncodeUrl}`;
          // url = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.shareVo.url || this.url}`
          window.open(url, '_blank');
        }
      },
      doCopy() {
        let url = this.currentUrl;
        this.$copyText(url)
          .then(e => {
            this.$vhMessage({
              message: `复制成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
          })
          .catch(error => {
            this.$vhMessage({
              message: `复制失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          });
      }
    }
  };
</script>

<style lang="less" scoped>
  @iconpath: '../../../common/images/icon';
  .content {
    .content-wrap {
      position: absolute;
      z-index: 2;
      width: 100%;
      bottom: 0;
      height: calc(100% - 50px);
      background: rgba(255, 255, 255, 0.5);
    }
    .independentForm {
      margin-top: 0px;
    }
    p {
      margin-top: 20px;
      overflow: hidden;
      line-height: 34px;
    }
    .content-key {
      color: #1a1a1a;
    }
    .sub {
      color: #999;
      font-size: 12px;
      margin-top: 0;
      text-indent: 70px;
    }
  }
  .share-div {
    display: inline-block;
    vertical-align: top;
    width: 322px;
    margin-right: 32px;
    //  .inputCode{}
  }
  .icons {
    text-align: left;
    width: 322px;
    display: inline-block;
    margin-bottom: 40px;
    li {
      list-style-type: none;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      i {
        display: inline-block;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(2) {
        i {
          background: url('@{iconpath}/qq.png') center center no-repeat;
          background-size: 100% 100%;
        }
        margin: 0 48px;
      }
      &:nth-child(3) {
        i {
          background: url('@{iconpath}/weibo.png') center center no-repeat;
          background-size: 100% 100%;
        }
      }
      &:nth-child(1) {
        i {
          background: url('@{iconpath}/wechat.png') center center no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 20px;
      margin-top: 6px;
    }
  }
  .code-div {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 148px;
    // border: 1px solid #CCCCCC;
    padding-left: 5px;
    .code-img {
      width: 148px;
      height: 148px;
      border: 1px solid #cccccc;
      padding: 8px;
      border-radius: 4px;
    }
    img {
      width: 132px;
      height: 132px;
    }
    .img-code {
      margin-top: 8px;
      line-height: 20px;
    }
  }
  .wximg-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    img {
      width: 132px;
      height: 132px;
    }
  }
  .wximg-intro {
    padding-bottom: 24px;
    line-height: 20px;
  }
</style>
